import Router from './routes';
import ThemeProvider from './theme';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import { Helmet } from 'react-helmet-async';
import { StyledChart } from './components/chart';
import { AutoLogoutProvider } from './components/AutoLogoutProvider';
import { useEffect } from 'react';
import { appEnvVariables, googleAnalytics, hotjar } from './config';
import Hotjar from '@hotjar/browser';
import ReactGA from 'react-ga4';
import { Environment } from './@types/types';

const { measurementId } = googleAnalytics;

const hotjarSiteId = hotjar.siteId;
const hotjarVersion = 6;

export default function App() {
  useEffect(() => {
    if (appEnvVariables.environment === Environment.PRODUCTION) {
      ReactGA.initialize(measurementId);

      Hotjar.init(hotjarSiteId, hotjarVersion, {
        debug: true,
      });
    }
  }, []);

  return (
    <>
      <Helmet>
        <script
          type="text/javascript"
          src={`/js/new_relic_${process.env.REACT_APP_ENVIRONMENT}.js`}
        />
      </Helmet>
      <MotionLazyContainer>
        <ThemeProvider>
          <AutoLogoutProvider>
            <ProgressBarStyle />
            <ScrollToTop />
            <StyledChart />
            <Router />
          </AutoLogoutProvider>
        </ThemeProvider>
      </MotionLazyContainer>
    </>
  );
}
