import { enUS, huHU } from '@mui/material/locale';
import { SettingsValueProps } from './components/settings/type';
import { Language } from './@types/location';

// APP ENVIRONMENT

export const appEnvVariables = {
  environment: process.env.REACT_APP_ENVIRONMENT || '',
  padminHost: process.env.REACT_APP_PADMIN_HOST || '',
  tixaHost: process.env.REACT_APP_TIXA_HOST || '',
  api: process.env.REACT_APP_HOST_API_KEY || '',
};

// FEATURE FLAG

export const featureFlags = {
  apiHost: process.env.REACT_APP_FEATURE_FLAGS_API_HOST,
  apiKey: process.env.REACT_APP_FEATURE_FLAGS_API_KEY,
  localOverrides: {
    guest_invitation: {
      enabled: true,
    },
    coupons: {
      enabled: true,
    },
  },
};

// HOTJAR

export const hotjar = {
  siteId: Number(process.env.REACT_APP_HOTJAR_SITE_ID) || 0,
};

// GOOGLE ANALYTICS

export const googleAnalytics = {
  measurementId: process.env.REACT_APP_GA_MEASUREMENT_ID || '',
};

// LAYOUT

export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 92,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

// SETTINGS

export const defaultSettings: SettingsValueProps = {
  themeMode: 'light',
  themeLayout: 'horizontal',
};

// MULTI LANGUAGES

export const allLangs = [
  {
    label: 'Magyar',
    value: Language.HU,
    systemValue: huHU,
    icon: '/assets/icons/flags/hu.svg',
  },
  {
    label: 'English',
    value: Language.EN,
    systemValue: enUS,
    icon: '/assets/icons/flags/gb.svg',
  },
];

export const defaultLang = allLangs[0];
